const dafaultLanguage = {
  scrollButtonText: {
    ja: "詳細",
    en: "Details",
  },
  philosophy: {
    philosophy1: {
      ja: "数学は世界で最も素晴らしいものである。なかでもe（ネイピア数）は特に美しいといわれ、あらゆる分野の計算に使われています。",
      en: "Mathematics is the most wonderful thing in the world. Among them, e (Napier numbers) is said to be particularly beautiful and is used for calculations in all fields."
    },
    philosophy2: {
      ja: "NAPIREは、e（ネイピア数）のように美しく、あらゆる分野に汎用性のあるものを作ることで、世界をより美しくすることを目指しています。",
      en: "NAPIRE aims to make the world more beautiful by creating something as beautiful as e (Napier number) and versatile to all fields."
    }
  },
  ceo: {
    ceo1: {
      ja: "2018年に北九州大学国際環境工学部を首席かつ飛び級で卒業。専門分野はAIの中でも特に画像検出・認識であり、画像を用いたソリューションを得意としている。",
      en: "In 2018, I graduated at the top of his class and skipped a grade in the International Environmental Engineering Department at the University of Kitakyushu. I specializes in image detection and recognition in AI, and is specialized in image-based solutions.",
    },
    ceo2: {
      ja: "また、フリーランスとしてAI開発やWebアプリケーションの開発を行うほか、さまざまな企業の新規プロジェクトに参加し、ビジネスの推進に貢献している。",
      en: "In addition to this, I develops web applications as a freelancer and participates in new projects for various companies, contributing to the promotion of their businesses."
    },
    detailButton: {
      ja: "詳細",
      en: "View Details"
    },
    detailHref: {
      ja: "https://www.linkedin.com/in/riku0325/",
      en: "https://www.linkedin.com/in/riku0325/"
    }
  },
  business: {
    business1: {
      title: {
        ja: "AI・IT開発 / コンサルティング",
        en: "AI・IT Development / Consulting"
      },
      detail: {
        ja: "最新のAI論文技術やIT技術を使って、お客様の手作業を自動化します。これまで、道路交通標識の画像検出・認識や、ECサイトの需要分析を行い、今後の事業戦略を提案する仕事などを手掛けてきました。",
        en: "I use the latest AI thesis technology to automate your manual work. I have worked on image detection and recognition of road traffic signs, as well as analyzing demand for e-commerce sites to propose future business strategies."
      }
    },
    business2: {
      title: {
        ja: "ビックデータ解析",
        en: "Big Data Analysis"
      },
      detail: {
        ja: "お客様の経営課題のビッグデータ分析を行っています。データを蓄積しているが、活用に困っている方は、ぜひご相談ください。",
        en: "I perform big data analysis of your business problems. If you have stored data but are having trouble utilizing it, please contact me."
      }
    },
    business3: {
      title: {
        ja: "ソフトウェア開発",
        en: "Software Development"
      },
      detail: {
        ja: "作りたいシステムやアプリケーションのイメージはあるが、それを作る技術がない方へ。私は一気通貫でシステムやアプリケーションを構築し、提供することができます。",
        en: "For those who have an idea of the systems and applications they want to create, but do not have the technology to create them. I can build and provide you with a one-stop shop."
      }
    }
  },
  contact: {
    description: {
      ja: "下記よりメールを送ってください。",
      en: "Please send a Email if you want to contact."
    },
    buttonText: {
      ja: "メール送信",
      en: "Call to send Email"
    }
  },
  company: {
    name: {
      ja: "会社名: 株式会社NAPIRE (英文社名: NAPIRE Inc.)",
      en: "Company Name: NAPIRE Inc."
    },
    builtDate: {
      ja: "設立日: 2024/01/04",
      en: "Established: 2024/01/04"
    },
    place: {
      ja: "オフィス所在地: 〒107-0062 東京都港区南青山3丁目1番36号青山丸竹ビル6F",
      en: "Office Location: 〒107-0062  Aoyamamarutake 6F, 3-1-36 Minamiaoyama, Minato-ku, Tokyo, Japan"
    },
    content: {
      ja: "事業内容: AI開発/コンサルティング、ビックデータ解析、ソフトウェア開発",
      en: "Business Content: AI Development/Consulting, Big Data Analysis, Software Development"
    },
    capital: {
      ja: "資本金: 1,000,000円",
      en: "Capital: 1,000,000 yen"
    }
  },
  vision: {
    title: {
      ja: "ビジョン",
      en: "vision"
    },
    description1: {
      ja: "弊社が持ち合わせているAI技術や高度IT技術を様々な企業に提供し、ビジネスに更なる加速を生み出します。",
      en: "We provide our AI and advanced IT technologies to various companies to further accelerate their business."
    },
    description2: {
      ja: "DX協業を行ってビジネスを加速させたい方はぜひご連絡ください。",
      en: "If you are interested in accelerating your business through DX collaboration, please contact us."
    },
    button: {
      ja: "連絡",
      en: "contact"
    },
    image: {
      ja: "dx_kyougyou.png",
      en: "dx_kyougyou_en.png"
    }
  }
}